.about {
  display: flex;
  flex-direction: column;
  padding: 0rem 12.8rem;
  gap: 12.8rem;
}

.about-wrap {
  perspective: 2rem;
}

/* Image Section */

.image-wrap {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(5, 1fr);
  align-items: center;
  margin-top: 5vh;
  gap: 2rem;
}

.about-text {
  font-size: 2.4rem;
  line-height: 1.3;
  grid-area: 2 / 3 / 5 / 7;
  transform-style: preserve-3d;
  transform: translateZ(-10px) scale(2);
  /* background-color: #0b6841; */
}

.img--grid-a {
  grid-area: 2 / 1;
  height: 20vh;
}
.img--grid-b {
  grid-area: 1 / 7 / 3 / 9;
  height: 32vh;
  align-self: center;
}
.img--grid-c {
  grid-area: 1 / 4 / 3 / 7;
  align-self: start;
  height: 30vh;
}
.img--grid-d {
  grid-area: 4 / 2 / 7 / 4;
  height: 32vh;
}
.img--grid-e {
  grid-area: 5 / 7 / 6 / 7;
  height: 20vh;
}
.img--grid-f {
  grid-area: 4 / 6 / 5 / 8;
  height: 20vh;
}
.img--grid-g {
  grid-area: 1 / 2 /2 /4;
  height: 10vh;
  align-self: center;
  justify-self: end;
}

/* ROLES */
.roles-title {
  font-size: 6.2rem;
}

.skills-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8rem;
}

.roles-header {
  display: flex;
  justify-content: space-between;
  gap: 12.8rem;
  margin: 0rem 0rem 12.8rem 0rem;
}

.role {
  font-size: 4.2rem;
  padding: 2rem;
  background-color: #0b6841;
}

.skills-header {
  display: grid;
  gap: 14rem;
  grid-template-columns: 1fr 5fr;
  padding: 10vw;
}

.skills-section {
  display: flex;
  flex-wrap: wrap;
}

.skills-title {
  font-size: 6.2rem;
}

.skill {
  font-size: 4rem;
  padding: 2rem;
}
