.work {
  display: flex;
  flex-direction: column;
  gap: 8rem;
  padding: 6vw 12.8rem 0vw 12.8rem;
  height: 100%;
  color: #fff;
}

/* WORK HEADER */

.work-header {
  /* ***** The 12.8 rem header doesn't look bad and I might use it actually. jsut not here */
  /* font-size: 12.8rem; */
  display: flex;
  flex-direction: column;

  padding: 10vh 0;
  gap: 2rem;
}

.work-title {
  font-size: 4.2rem;
}

.work-text {
  font-size: 3.2rem;
}

.work-wrap {
  display: flex;
  flex-direction: column;
  gap: 4rem;
}

.work-controls-wrap {
  display: flex;
  gap: 8rem;
}

/* Work button */

.work-button,
.work-button:visited {
  font-size: 3rem;
  font-weight: 600;
  padding: 1.2rem 3.2rem;
  height: 7rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: #167958;
  border: none;
  transition: 0.3s all ease;
}

.work-button:hover,
.work-button:active {
  scale: 1.1;
  background-color: #0c573e;
  transition: 0.3s all ease;
}

/* WORK PROJECT CSS */
/* **********  ********** **********  ********** */

.work-projects-wrap {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.work-projects-wrap a:link {
  text-decoration: none;
}

.work-project {
  transition: 0.3s all ease;
  text-decoration: none;
}

.work-project:link,
.work-project:visited {
  text-decoration: none;
  transition: 0.3s all ease;
}

.work-project:hover,
.work-project:active {
  background-color: #0c573e;
  padding: 1.6rem;
  scale: 1.04;
  transition: 0.3s all ease;
}

.work-project {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  column-gap: 6.2rem;
}

.work-project-image {
  width: 12.8rem;
  height: 100%;
  object-fit: cover;
  grid-row: 1 / 3;
}

.work-project-title {
  font-size: 4.2rem;
}

.work-project-text {
  grid-column: 2;
  font-size: 2.2rem;
}

.inactive {
  opacity: 0.7;
  filter: grayscale(100%);
  cursor: none;
}

.inactive:link,
.inactive:visited {
  text-decoration: none;
  transition: 0.3s all ease;
}

.inactive:hover,
.inactive:active {
  scale: none;
  transition: 0.3s all ease;
  padding: 0rem;
  background-color: #0c573e00;
}

/* ********** PS 2 PAGE ********** */
/* **********  ********** **********  ********** */

.project {
  display: flex;
  flex-direction: column;
  gap: 10vh;
  height: 100%;
  background-color: #fff;
}

.project-heading-section {
  margin: 10vh 0vw 10vh 0vw;
  font-size: 20.2rem;
  background-color: #0c573e00;
  color: #001a0e;
  padding: 4rem;
  transition: 0.3s all ease;
  /* mix-blend-mode: exclusion; */
  text-align: center;
}

/* **********  ********** **********  ********** */
/* Project Header */
/* **********  ********** **********  ********** */

.project-header-wrap {
  display: flex;
  flex-direction: column;
  width: 100vw;
  justify-content: center;
  align-items: center;
}

.project-header-wrap > div {
  transition: 0.3s all ease;
}

.project-image {
  height: 100vh;
  object-fit: cover;
  background-position: center center;
  border-bottom: 0.2rem solid #707070;
}

.project-header {
  margin-top: 10vh;
  font-size: 20.2rem;
  width: 100%;
  background-color: #0c573e00;
  padding: 4rem;
  /* margin-top: 55vh; */
  transition: 0.3s all ease;
  mix-blend-mode: exclusion;
  text-align: center;
  line-height: 1;
}

.project-content {
}

/* **********  ********** **********  ********** */
/* Project Header */
/* **********  ********** **********  ********** */

.project-details {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 5vh;
  color: black;
}

.project-details-wrap-top {
  display: flex;
  flex-direction: row;
  gap: 6.2rem;
  padding: 3rem 20vw 3rem 20vw;
  border-bottom: 0.2rem solid #707070;
  border-top: 0.2rem solid #707070;
}

.project-details-wrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1.2rem;
}

.project-details-title {
  font-size: 3.2rem;
  /* font-weight: 700; */
}

.project-details-text {
  font-size: 2.2rem;
  /* font-weight: 600; */
}

.project-header-challenge-wrap {
  background-color: white;
  padding: 0rem 15vw 0rem 15vw;
  height: 120vh;
  mix-blend-mode: exclusion;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.project-details-challenge {
  grid-column: 1/5;
  font-size: 6rem;
}

.project-details-challenge-text {
  padding: 0rem 5vw 0rem 5vw;
  font-size: 2.2rem;
}

.margin-left {
  margin-left: 30vw;
}
.margin-right {
  margin-right: 30vw;
}

/* **********  ********** **********  ********** */
/* PROJECT SHOWCASE SECTION */
/* **********  ********** **********  ********** */

.project-showcase {
  height: 100%;
  display: grid;
  grid-template-columns: auto auto auto auto auto auto;
  grid-template-rows: auto auto auto auto auto auto;
  padding: 30vh 20vw 30vh 20vw;
  /* margin: 10vh 0vw 10vh 0vw; */
  background-color: #001a0e;
  gap: 2.2rem;
}

.project-showcase-text {
  height: 100%;
  font-size: 3.2rem;
  font-weight: 500;
  box-shadow: 0px 20px 20px 0px #00000050;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  padding: 3rem;
  border-radius: 1.6rem;
  line-height: 1;
  transition: 0.3s all ease;
  background-color: #ffffff !important;
  color: #202020;
}

.project-showcase-text-highlight {
  font-size: 6.2rem;
  font-weight: 800;
  margin-right: 1rem;
  /* mix-blend-mode: exclusion; */
  color: #00533f;
  text-shadow: 0rem 0rem 2rem #00000020;
  z-index: 3;
}

.project-showcase-text:nth-child(1) {
  grid-column: 1/3;
  grid-row: 1/4;
  background-color: #045846; /* Teal variation 1 */
}

.project-showcase-text:nth-child(2) {
  grid-column: 3/5;
  grid-row: 1/2;
  background-color: #006666; /* Teal variation 2 */
}

.project-showcase-text:nth-child(3) {
  grid-column: 5/7;
  grid-row: 1/3;
  background-color: #009999; /* Teal variation 3 */
}

.project-showcase-text:nth-child(4) {
  grid-column: 3/4;
  grid-row: 2/5;
  background-color: #093e57; /* Teal variation 4 */
}

.project-showcase-text:nth-child(5) {
  grid-column: 2/7;
  grid-row: 5/7;
  background-color: #084437; /* Teal variation 5 */
}

.project-showcase-text:nth-child(6) {
  grid-column: 1/2;
  grid-row: 4/7;
  background-color: #114646; /* Teal variation 3 */
}

.project-showcase-text:nth-child(7) {
  grid-column: 4/6;
  grid-row: 3/5;
  background-color: #006666; /* Teal variation 2 */
}

.project-showcase-text:nth-child(8) {
  grid-column: 6/7;
  grid-row: 3/5;
  background-color: #093b10; /* Teal variation 2 */
}

.project-showcase-text:nth-child(9),
.project-showcase-text:nth-child(10) {
  background-color: #118080; /* Teal variation 1 */
}

.strong-different-color {
  color: #046b7e;
}

.strong-different-color-a {
  color: #047e3b;
}

.strong-different-color-b {
  color: #079975;
}

.strong-different-color-c {
  color: #2393dd;
}
/* **********  ********** **********  ********** */
/* Project page split. Section where the page is split in two and a 
picture takes half of the page and the text the other half*/
/* **********  ********** **********  ********** */

.project-page-split {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0vh 10vw 0vh 10vw;
  gap: 10vw;
  position: relative;
}

/* Top and bottom lines for the div */

/* .project-page-split::after {
  content: "";
  height: 2px;
  width: 80vw;
  background-color: #707070;
  position: absolute;
  bottom: 0;
  border-radius: 5px;
  margin-bottom: 5vh;
}

.project-page-split::before {
  content: "";
  height: 2px;
  width: 80vw;
  background-color: #707070;
  position: absolute;
  top: 0;
  border-radius: 5px;
  margin-top: 5vh;
} */

.split-small {
  height: 60vh;
  padding: 10vh 10vw 10vh 10vw;
}

.right {
  order: 0;
}

.left {
  order: 1;
}

.project-page-image {
  width: 40vw;
}

.project-page-split-text {
  width: 50vw;
  font-size: 2.2rem;
  color: #202020;
}

/* **********  ********** **********  ********** */
/* PROJECT TEXT BLOCK */
/* **********  ********** **********  ********** */

.project-text {
  font-size: 2.2rem;
  color: #202020;
  padding: 10vh 20vw 10rem 20vw;
}

/* PROJECT TEXT BLOCK A */

.project-text-wrap {
  display: flex;
  flex-direction: column;
  gap: 5vh;
  color: #202020;

  padding: 0vh 15vw 0vh 15vw;
}

.project-text-a {
  font-size: 2.2rem;
  width: 30vw;
  margin-left: 5vw;
}

.project-text-aBis {
  font-size: 6rem;
  line-height: 1.3;
}

/* **********  ********** **********  ********** */
/* PROJECT TEXTIMAGE BLOCK */
/* **********  ********** **********  ********** */

.project-textimage-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10vw;
  padding: 10vh 15vw 10vh 10vw;
  position: relative;
}

.project-textimage-wrap::after {
  content: "";
  height: 2px;
  width: 80vw;
  background-color: #707070;
  position: absolute;
  bottom: 0;
  border-radius: 5px;
  margin-bottom: 5vh;
}

.project-textimage-wrap::before {
  content: "";
  height: 2px;
  width: 80vw;
  background-color: #707070;
  position: absolute;
  top: 0;
  border-radius: 5px;
  margin-top: 5vh;
}

.project-textimage-a {
  font-size: 2.2rem;
  line-height: 1.5;

  color: #202020;
}

.project-textimage-image {
  height: 90vh;
  /* box-shadow: 0px 20px 20px 0px #00000050; */
}

/* **********  ********** **********  ********** */
/* PROJECT PARALLAX SECTION */
/* **********  ********** **********  ********** */

.parallax-images-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 0.1fr 1fr 0.1fr 1fr;
  padding: 10vh 10vw 5vh 10vw;
  gap: 2rem;
}

.parallax-image {
  height: 22.2rem;
  box-shadow: 0px 10px 20px 0px #00000015;
}

.parallax-text {
  font-size: 2.2rem;
  grid-column: 2/6;
  font-weight: 200;
  grid-row: 3/4;
  z-index: 3;
  color: #e0e0e0;
  mix-blend-mode: exclusion;
}

/* **********  ********** **********  ********** */
/* PROJECT RAND IMAGES SECTION */
/* **********  ********** **********  ********** */

.project-researchQuestions-image {
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-top: -12vh; */
  padding: 0vh 20vw 0vh 20vw;
}

/* **********  ********** **********  ********** */
/* PROJECT SECTION HEADER SECTION */
/* **********  ********** **********  ********** */

.section-header {
  height: 120vh;
  mix-blend-mode: exclusion;
  background-color: white;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.section-header-title {
  font-size: 20.2rem;
  padding: 4rem;
  transition: 0.3s all ease;
  text-align: center;
}

.section-header-text {
  font-size: 2.2rem;
  padding: 4rem;
  transition: 0.3s all ease;
  text-align: start;
  padding: 0vh 20vw 0vh 20vw;
}

.section-header-text > strong {
  color: #e95092;
}

.quote {
  color: #202020;
  font-size: 12.2rem;
  line-height: normal;
  text-align: center;
  padding: 14rem;
}

/* EXP Parallax */

.experiment-parallax {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
  padding: 5vh 10vw 5vh 10vw;
  gap: 2rem;
  width: 100vw;
  justify-content: center;
  align-items: center;
}

.experiment-parallax-text {
  mix-blend-mode: exclusion;
  font-size: 2.2rem;
  grid-column: 2/6;
  grid-row: 3/3;
  font-weight: 300;
}

.experiment-parallax-image {
  height: 18.2rem;
  box-shadow: 0px 10px 20px 0px #00000015;
}

.imagea {
  grid-column: 1/1;
  grid-row: 1/1;
}

.imageb {
  grid-column: 3/6;
  grid-row: 1/3;
  align-self: center;
  justify-self: center;
}

.imagec {
  grid-column: 6/7;
  grid-row: 3/3;
}

.imaged {
  grid-column: 2/3;
  grid-row: 6/6;
  width: 30rem;
  height: auto;
}

/* ADDED THIS SINCE IT DOESN'T REACT TO N'TH CHILD */
.imaged-bis {
}

.imagee {
  grid-column: 5/5;
  grid-row: 5/5;
  width: 20rem;
  height: auto;
}

.imagef {
  grid-column: 1/1;
  grid-row: 4/5;
}

/* insights */

.insights {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 5rem;
  margin: 5vh 20vw 5vh 20vw;
}

.insights-text {
  padding: 3rem;
  font-size: 1.6rem;
  background-color: #f7f7f7;
  color: #202020;
  border-radius: 1.6rem;
  padding: 2.6rem;
  box-shadow: 0px 20px 20px 0px #00000015;
}

.grad-project-heading-extra {
  font-size: 8.2rem;
  color: #202020;
  padding: 0vh 20vw 0vh 20vw;
}

/* ********** PS 2 PAGE ********** */
/* ********** PS 2 PAGE ********** */
/* ********** PS 2 PAGE ********** */
