.contact {
  display: flex;
  height: 70vh;
  flex-direction: row;

  justify-content: center;
  align-items: center;
  padding-top: 20vh;
}

.contact-wrap {
  display: flex;
  margin-right: 20rem;
  gap: 5rem;
}

.contact-header {
  font-size: 7.2rem;
  width: 45rem;
}

.contact-form {
  display: flex;
  flex-direction: column;
  width: 40vw;
  font-size: 2.6rem;
}

.label {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.input-text {
  width: 100%;
  height: 3.2rem;
  background-color: rgba(240, 248, 255, 0);
  border: none;
  /* border-bottom: 0.4rem solid #fff; */
  border-radius: 5px;
  display: flex;
  align-items: flex-end;
  padding: 0.2rem 1rem;
  font-size: 2.2rem;
  color: #fff;
  transition: 0.3s all ease;
}

.input-text:focus {
  outline: none;
  background-color: #ffffff20;
  margin-bottom: 1rem;
  height: 4rem;
}

.input-text::placeholder {
  font-size: 2.2rem;
  color: #ffffff70;
}

.input-field {
  height: 12rem;
}

.input-field:focus {
  height: 14rem;
}

.input-line {
  height: 3px;
  width: 100%;
  border-radius: 10px;
  background-color: #ffffff90;
}

.submit,
.submit:link,
.submit:visited {
  font-size: 2.2rem;
  font-weight: 600;
  padding: 1.2rem;
  height: 7rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: #167958;
  border: none;
  transition: 0.3s all ease;
}

.submit:hover,
.submit:active {
  scale: 1.1;
  background-color: #0c573e;
  transition: 0.3s all ease;
}

.contact-button-wrap {
  width: 20rem;
}

.color-heighlight-a {
  color: #00ffb3;
}
