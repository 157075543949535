:root {
  --color-bg1: #0c7955;
  --color-bg2: rgb(12, 133, 153);
  --color1: 32, 144, 132;
  --colora: #003d1f;
  --colorb: #002914;
  --colorc: #003a27;
  --colord: #00532e;
  --colore: #003f2a;

  --color-interactive: 12, 133, 153;
  --circle-size: 150vh;
  --blending: hard-light;
}

@keyframes moveInCircle {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes moveVertical {
  0% {
    transform: translateY(-50%);
  }
  50% {
    transform: translateY(50%);
  }
  100% {
    transform: translateY(-50%);
  }
}

@keyframes moveHorizontal {
  0% {
    transform: translateX(-50%) translateY(-10%);
  }
  50% {
    transform: translateX(50%) translateY(10%);
  }
  100% {
    transform: translateX(-50%) translateY(-10%);
  }
}

.svg-background {
  height: 0px;
}

.gradient-bg {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  // top: 0;
  // left: 0;
  z-index: 0;

  svg {
    display: none;
    // z-index: -1;
  }

  .gradients-container {
    filter: url(#goo) blur(5rem);
    width: 100%;
    height: 100%;
    // position: relative;
    z-index: -1;

    // z-index: -2;
  }

  //   .g1,
  //   .g2,
  //   .g3,
  //   .g4,
  //   .g5 {
  //     filter: url(#goo) blur(40px);
  //   }

  .g1 {
    position: absolute;
    background: radial-gradient(
        circle at center,
        var(--colora) 0,
        rgba(var(--color1), 0) 50%
      )
      no-repeat;
    mix-blend-mode: var(--blending);

    width: var(--circle-size);
    height: var(--circle-size);
    top: calc(50% - var(--circle-size) / 2);
    left: calc(50% - var(--circle-size) / 2);

    transform-origin: center center;
    animation: moveVertical 30s ease infinite;

    opacity: 1;
  }

  .g2 {
    position: absolute;
    background: radial-gradient(
        circle at center,
        var(--colorb) 0,
        rgba(var(--color1), 0) 50%
      )
      no-repeat;
    mix-blend-mode: var(--blending);

    width: var(--circle-size);
    height: var(--circle-size);
    top: calc(50% - var(--circle-size) / 2);
    left: calc(50% - var(--circle-size) / 2);

    transform-origin: calc(50% - 400px);
    animation: moveInCircle 20s reverse infinite;

    opacity: 1;
  }

  .g3 {
    position: absolute;
    background: radial-gradient(
        circle at center,
        var(--colorc) 0,
        rgba(var(--color1), 0) 50%
      )
      no-repeat;
    mix-blend-mode: var(--blending);

    width: var(--circle-size);
    height: var(--circle-size);
    top: calc(50% - var(--circle-size) / 2 + 200px);
    left: calc(50% - var(--circle-size) / 2 - 500px);

    transform-origin: calc(50% + 400px);
    animation: moveInCircle 40s linear infinite;

    opacity: 1;
  }

  .g4 {
    position: absolute;
    background: radial-gradient(
        circle at center,
        var(--colord) 0,
        rgba(var(--color1), 0) 50%
      )
      no-repeat;
    mix-blend-mode: var(--blending);

    width: var(--circle-size);
    height: var(--circle-size);
    top: calc(50% - var(--circle-size) / 2);
    left: calc(50% - var(--circle-size) / 2);

    transform-origin: calc(50% - 200px);
    animation: moveHorizontal 40s ease infinite;

    opacity: 0.7;
  }

  .g5 {
    position: absolute;
    background: radial-gradient(
        circle at center,
        var(--colore) 0,
        rgba(var(--color1), 0) 50%
      )
      no-repeat;
    mix-blend-mode: var(--blending);

    width: calc(var(--circle-size) * 2);
    height: calc(var(--circle-size) * 2);
    top: calc(50% - var(--circle-size));
    left: calc(50% - var(--circle-size));

    transform-origin: calc(50% - 800px) calc(50% + 200px);
    animation: moveInCircle 20s ease infinite;

    opacity: 1;
  }

  .interactive {
    position: absolute;
    background: radial-gradient(
        circle at center,
        rgba(var(--color-interactive), 0.8) 0,
        rgba(var(--color-interactive), 0) 50%
      )
      no-repeat;
    mix-blend-mode: var(--blending);

    width: 100%;
    height: 100%;
    top: -50%;
    left: -50%;

    opacity: 0.7;
  }
}

// Gradient background

.background-gradient {
  background: linear-gradient(
    348deg,
    #0b6b52,
    #105744,
    #0a7880,
    #1b5238,
    #0d5732
  );
  background-size: 1000% 1000%;

  -webkit-animation: AnimationName 40s ease infinite;
  -moz-animation: AnimationName 40s ease infinite;
  animation: AnimationName 40s ease infinite;
}

@-webkit-keyframes AnimationName {
  0% {
    background-position: 0% 13%;
  }
  50% {
    background-position: 100% 88%;
  }
  100% {
    background-position: 0% 13%;
  }
}
@-moz-keyframes AnimationName {
  0% {
    background-position: 0% 13%;
  }
  50% {
    background-position: 100% 88%;
  }
  100% {
    background-position: 0% 13%;
  }
}
@keyframes AnimationName {
  0% {
    background-position: 0% 13%;
  }
  50% {
    background-position: 100% 88%;
  }
  100% {
    background-position: 0% 13%;
  }
}
