.swiper {
  width: 100vw;
  height: 80vh;
  margin: 0rem;
}

.swiper-wrapper {
  align-items: center;
}

.carousel-wrapper {
  height: 100%;
  overflow: hidden;
}

/* ******* SWIPER SLIDE ******* */

.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50rem;
  height: 40rem;
}

/* The tilt div */

.swiper-slide div {
  width: 100%;

  /* Tilt parallax effect */
  transform-style: preserve-3d;
  transform: perspective(1000px);
}

.project-cards-wrap {
  position: relative;
}

.project-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1.6rem;
  padding: 3.6rem;
  height: 40rem;
  width: 100%;
  color: #fff;

  /* Tilt parallax effect */

  transform-style: preserve-3d;
  transform: translateZ(20px);
}

.project-title {
  font-size: 6.2rem;
  font-weight: 700;

  /* Tilt parallax effect */
  transform: translateZ(20px);
}

.project-description {
  font-size: 2.2rem;

  /* Tilt parallax effect */
  transform: translateZ(20px);
}

.card-bottoma {
  /* z-index: 0; */
  position: absolute;
  /* transform: rotate(10deg) !important; */
}

.card-bottomb {
  /* z-index: 0; */
  position: absolute;
  /* transform: rotate(-10deg) !important; */
}

.project-cards-wrap:hover > .card-bottoma {
  transform: rotate(10deg) !important;
  scale: 1.05;
}

.project-cards-wrap:hover > .card-bottomb {
  transform: rotate(-10deg) !important;
  scale: 1.05;
}

.card-front {
  z-index: 1;
}

/* ******* PROJECTS SLIDE ******* */
/* *****-- PS2 SLIDE --****** */

.ps2 {
  background-image: url("../../Images/ps2.webp");
  background-size: cover;
  /* z-index: 3; */
}

.ps3d {
  background-image: url("../../Images/grad.webp");
  background-size: cover;
  /* z-index: 3; */
}
.mrc {
  background-image: url("../../Images/grad.webp");
  background-size: cover;
  /* z-index: 3; */
}

/* *****-- Grad SLIDE --****** */

.grad {
  background-image: url("../../Images/grad.webp");
  background-size: cover;
  /* z-index: 3; */
}

.grad-main-pica {
  background-image: url("../../Images/grad/gra-ph1a\ \(5\).webp");
  background-size: cover;
}

.grad-main-picb {
  background-image: url("../../Images/grad/grad-experimenta.webp");
  background-size: cover;
}

/* *****-- GRADDESIGN SLIDE --****** */

.graddesign {
  background-image: url("../../Images/graddesignCover.webp");
  background-size: cover;
}

/* *****-- INACTIVE SLIDE --****** */

.inactive {
  opacity: 0.7;
  filter: grayscale(100%);
  cursor: none;
}

.inactive:hover,
.inactive:active {
  scale: 1;
}

/* deactivating the animations of hovering over a card */

.inactive:hover div div .card-bottoma {
  transform: rotate(0deg) !important;
  scale: 1 !important;
}

.inactive:hover div div .card-bottomb {
  transform: rotate(0deg) !important;
  scale: 1 !important;
}
